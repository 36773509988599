import SkeletonLayout from "../../skeletonLayout/SkeletonLayout";
import "./index.css";

const CheckBox = ({ data, actives, onChange }) => {
  return (
    <>
      {data.length ? (
        <div className="checkBox">
          {data.map((user, i) => (
            <div
              key={i}
              className="items"
              onClick={() => onChange(user.user_id)}
            >
              <div
                className={`box ${
                  actives.includes(user.user_id) ? "active" : ""
                } `}
              >
                <svg
                  width="17"
                  height="11"
                  viewBox="0 0 17 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.1699 0.248697C16.0106 0.0894547 15.7945 0 15.5693 0C15.344 0 15.128 0.0894547 14.9686 0.248697L6.50108 8.71626L1.43676 3.64877C1.27628 3.49533 1.06211 3.41081 0.84009 3.4133C0.618072 3.41579 0.405849 3.5051 0.248848 3.6621C0.0918474 3.8191 0.00254409 4.03132 5.35203e-05 4.25334C-0.00243705 4.47536 0.082083 4.68953 0.235522 4.85001L5.89966 10.5189C6.05898 10.6782 6.27502 10.7676 6.50028 10.7676C6.72554 10.7676 6.94158 10.6782 7.1009 10.5189L16.1699 1.44994C16.3291 1.29062 16.4186 1.07458 16.4186 0.849318C16.4186 0.624059 16.3291 0.408019 16.1699 0.248697V0.248697Z"
                    fill="white"
                  />
                </svg>
              </div>
              <p>{user.full_name}</p>
            </div>
          ))}
        </div>
      ) : (
        <SkeletonLayout />
      )}
    </>
  );
};
export default CheckBox;
