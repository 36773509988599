import { useRef } from "react";

const useUniqueId = () => {
  const { current: id } = useRef(
    Date.now().toString(36) + Math.random().toString(36).slice(2)
  );
  return id;
};

export default useUniqueId;
