import "./index.css";
import slack from "../../../assets/slack.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AddSlackBtn from "../../addSlackBtn";

const InstallationSteps = ({ data }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div
      className={`installation-steps ${
        data.right ? "right-step" : "left-step"
      }`}
    >
      <div className="step-image">
        <img
          src={data.img}
          alt="stepImage"
          data-aos={`fade-${data.right ? "left" : "right"}`}
          data-aos-delay="1100"
        />
      </div>
      <div
        className="step-info"
        data-aos={`fade-${data.right ? "right" : "left"}`}
        data-aos-delay="1100"
      >
        <div className="step-info--inner">
          <p className="step-title">{data.title}</p>
          <div className="step-describe">{data.describe}</div>
        </div>
        <div className="btn-container">
          <AddSlackBtn isDark={data.right ? false : true} />
        </div>
      </div>
      <div className="installation-steps--back">{data.background}</div>
    </div>
  );
};

export default InstallationSteps;
