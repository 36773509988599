import GitHubIcon from "../../assets/patterns/GitHubIcon";
import { INSTALL_STEPS } from "../../static/installSteps";
import { GITHUB_URL } from "../../static/introStatic";
import "./index.css";
import InstallationSteps from "./steps";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Installation = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  function goToHitHub() {
    window.location.href = GITHUB_URL;
  }

  return (
    <div
      className="installation-container"
      data-aos="fade-up"
      data-aos-delay="500"
    >
      <p className="installation-container--title">
        How to set an initial set up
        <br /> for your workspace
      </p>
      <p className="installation-container--description">
        Login with your Github account to view Kianbot's page.
        <br /> You will be guided through the activation process.
      </p>
      <button
        onClick={goToHitHub}
        className="installation-container--github-action"
      >
        <GitHubIcon />
        <p>Github</p>
      </button>
      {INSTALL_STEPS.map((step, i) => {
        return <InstallationSteps key={i} data={step} />;
      })}
    </div>
  );
};
export default Installation;
