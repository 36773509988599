const MobilePattern = () => (
  <svg
    width="272"
    height="244"
    viewBox="0 0 272 244"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.75"
      d="M79.2665 11.1703C130.304 29.3699 171.573 -7.18067 203.143 -26.5962C234.714 -46.0088 279.384 -32.5413 270.99 26.2571C262.594 85.0593 210.603 147.291 116.011 133.194C77.7156 127.486 46.0675 100.989 34.1899 76.0916C18.1057 42.3817 28.2328 -7.02726 79.2703 11.1723L79.2665 11.1703Z"
      fill="url(#paint0_linear_1_10)"
    />
    <g opacity="0.75">
      <path
        d="M44.7817 41.5057C37.3573 43.8491 30.6933 48.0066 25.503 53.5294C19.9938 59.3959 16.2943 66.5022 14.5024 74.6554C11.749 87.2007 13.8542 101.698 20.5949 116.58C28.4273 133.873 42.2751 151.633 61.76 169.37C61.9073 169.508 76.757 183.158 101.033 193.616C115.291 199.758 129.955 203.608 144.624 205.059C162.952 206.871 181.337 204.923 199.267 199.264C210.413 195.745 221.567 190.728 232.409 184.347C251.526 173.104 264.396 156.925 269.63 137.557C274.248 120.474 272.676 101.817 265.204 85.0214C258.709 70.4255 248.228 58.549 235.687 51.5766C221.909 43.9188 206.39 42.4706 190.799 47.3918C189.335 47.8538 187.849 48.378 186.386 48.9533C182.072 50.6454 177.834 52.1482 173.796 53.4227C132.766 66.3737 110.38 56.7323 82.0408 44.5249L79.826 43.5716C68.4726 38.6897 56.0287 37.9556 44.7875 41.5038L44.7817 41.5057ZM44.6394 41.0549C55.5919 37.5977 68.0994 38.0203 80.0068 43.1388C109.144 55.6679 131.72 66.2083 173.651 52.9728C177.651 51.7105 181.833 50.2283 186.211 48.5128C187.701 47.9291 189.181 47.4067 190.654 46.9419C261.643 24.5343 311.282 138.497 232.644 184.753C221.26 191.45 210.151 196.32 199.405 199.712C120.534 224.608 61.4351 169.716 61.4351 169.716C-10.1266 104.56 9.02521 52.2964 44.6423 41.054L44.6394 41.0549Z"
        fill="white"
      />
    </g>
    <path
      opacity="0.75"
      d="M295.369 51.5233C276.774 65.5098 261.75 84.9345 258.091 108.229C253.853 135.205 247.538 151.507 207.9 154.948C167.516 158.455 114.527 133.053 92.6213 108.525C70.8943 84.1993 55.421 42.7172 75.2325 6.66936C95.044 -29.3785 128.53 -34.3282 152.099 -23.3159C175.668 -12.3037 217.456 -6.74437 261.033 -11.866C304.609 -16.9877 318.629 1.62356 320.489 12.4606C323.133 27.8529 310.497 40.1425 295.369 51.5202L295.369 51.5233Z"
      fill="url(#paint1_linear_1_10)"
    />
    <path
      opacity="0.75"
      d="M88.0173 151.987C118.971 145.217 141.704 149.707 155.436 169.606C170.801 191.874 177.025 211.48 204.456 218.706C238.908 227.782 279.799 201.216 290.022 152.573C295.071 128.557 294.338 94.0678 268.804 68.2294C250.053 49.2569 217.251 45.563 192.526 57.796C174.303 66.8137 150.929 76.4304 125.842 65.1078C100.756 53.7883 71.9597 55.8799 54.2507 79.4732C34.6536 105.58 51.6054 159.952 88.0143 151.988L88.0173 151.987Z"
      fill="url(#paint2_linear_1_10)"
    />
    <path
      opacity="0.5"
      d="M117.081 188.241C112.132 189.803 106.854 187.058 105.292 182.109C103.73 177.16 106.476 171.882 111.424 170.32C116.373 168.758 121.651 171.503 123.213 176.452C124.775 181.401 122.03 186.679 117.081 188.241Z"
      fill="url(#paint3_linear_1_10)"
    />
    <path
      opacity="0.75"
      d="M63.9042 193.033C61.4298 193.814 58.7908 192.441 58.0097 189.967C57.2287 187.492 58.6015 184.853 61.0759 184.072C63.5503 183.291 66.1893 184.664 66.9703 187.138C67.7514 189.613 66.3786 192.252 63.9042 193.033Z"
      fill="url(#paint4_linear_1_10)"
    />
    <path
      opacity="0.3"
      d="M91.1879 227.899C84.9877 229.856 78.3743 226.414 76.4164 220.211C74.4586 214.009 77.8976 207.394 84.0978 205.437C90.298 203.48 96.9114 206.921 98.8693 213.124C100.827 219.327 97.3881 225.942 91.1879 227.899Z"
      fill="url(#paint5_linear_1_10)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_10"
        x1="277.228"
        y1="18.2655"
        x2="38.952"
        y2="93.4765"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9EAAE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_10"
        x1="71.2242"
        y1="79.6637"
        x2="324.567"
        y2="36.3117"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF59D" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1_10"
        x1="292.79"
        y1="130.861"
        x2="48.3845"
        y2="146.382"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F9EAAE" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1_10"
        x1="120.6"
        y1="172.35"
        x2="107.906"
        y2="186.211"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF59D" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1_10"
        x1="66.6577"
        y1="190.721"
        x2="58.3212"
        y2="186.384"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9EAAE" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1_10"
        x1="98.0869"
        y1="222.106"
        x2="77.1913"
        y2="211.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F9EAAE" />
      </linearGradient>
    </defs>
  </svg>
);
export default MobilePattern;
