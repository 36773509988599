import "./index.css";
import kinaLogo from "../../assets/logotype.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-logo">
        <img src={kinaLogo} alt="logo" />
      </div>
      <div className="footer">
        <p>
          Contact us at
          <span>
            <a href="mailto:info@kianbot.com">info@kianbot.com</a>
          </span>
          if you have any questions.
        </p>
        <p>Copyright © Kianbot.com - 2023</p>
      </div>
    </div>
  );
};
export default Footer;
