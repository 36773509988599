// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container{
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 1rem;
    z-index: 2;
    }

.gradient-bg{
    width: 80%;
    height: 95px;
    border-radius: 1rem;
    margin: 0rem 2rem;
    background: rgb(251,225,217);
background: linear-gradient(152deg, rgba(251,225,217,1) 0%, rgba(253,229,188,1) 16%, rgba(211,233,184,1) 33%, rgba(181,225,232,1) 49%, rgba(210,194,218,1) 64%, rgba(239,218,231,1) 77%, rgba(250,237,241,1) 90%, rgba(251,238,242,1) 91%);
}

.header-container img{
    height: 53px;
    width: auto;
}


@media only screen and (max-width: 780px) {
    .gradient-bg{
        height: 55px;
        width: 100%;
        margin: 0rem 0.5rem;
    }
    .header-container img{
        margin-left: 1rem;
        height: 32px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/addPage/header/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV;;AAEJ;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;IACjB,4BAA4B;AAChC,0OAA0O;AAC1O;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;;AAGA;IACI;QACI,YAAY;QACZ,WAAW;QACX,mBAAmB;IACvB;IACA;QACI,iBAAiB;QACjB,YAAY;IAChB;AACJ","sourcesContent":[".header-container{\n    display: flex;\n    justify-content: center;\n    align-items: start;\n    margin-top: 1rem;\n    z-index: 2;\n    }\n\n.gradient-bg{\n    width: 80%;\n    height: 95px;\n    border-radius: 1rem;\n    margin: 0rem 2rem;\n    background: rgb(251,225,217);\nbackground: linear-gradient(152deg, rgba(251,225,217,1) 0%, rgba(253,229,188,1) 16%, rgba(211,233,184,1) 33%, rgba(181,225,232,1) 49%, rgba(210,194,218,1) 64%, rgba(239,218,231,1) 77%, rgba(250,237,241,1) 90%, rgba(251,238,242,1) 91%);\n}\n\n.header-container img{\n    height: 53px;\n    width: auto;\n}\n\n\n@media only screen and (max-width: 780px) {\n    .gradient-bg{\n        height: 55px;\n        width: 100%;\n        margin: 0rem 0.5rem;\n    }\n    .header-container img{\n        margin-left: 1rem;\n        height: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
