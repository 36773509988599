const GitHubIcon = () => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_97_468)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9583 0C6.23971 0 0 6.1875 0 13.8423C0 19.9612 3.998 25.1407 9.54429 26.9738C10.2377 27.1117 10.4917 26.676 10.4917 26.3095C10.4917 25.9886 10.4689 24.8887 10.4689 23.7426C6.586 24.5677 5.77743 22.0925 5.77743 22.0925C5.15343 20.4882 4.22886 20.0759 4.22886 20.0759C2.958 19.2279 4.32143 19.2279 4.32143 19.2279C5.73114 19.3196 6.47086 20.6488 6.47086 20.6488C7.71857 22.7571 9.72914 22.1614 10.538 21.7946C10.6534 20.9008 11.0234 20.2821 11.4163 19.9384C8.31943 19.6175 5.06114 18.4258 5.06114 13.1088C5.06114 11.5962 5.61543 10.3587 6.49371 9.39628C6.35514 9.05259 5.86971 7.63144 6.63257 5.72934C6.63257 5.72934 7.81114 5.36259 10.4686 7.15022C11.6063 6.84722 12.7796 6.69308 13.9583 6.69178C15.1369 6.69178 16.3383 6.85238 17.4477 7.15022C20.1054 5.36259 21.284 5.72934 21.284 5.72934C22.0469 7.63144 21.5611 9.05259 21.4226 9.39628C22.324 10.3587 22.8554 11.5962 22.8554 13.1088C22.8554 18.4258 19.5971 19.5944 16.4771 19.9384C16.9857 20.3737 17.4246 21.1987 17.4246 22.5051C17.4246 24.3613 17.4017 25.8511 17.4017 26.3092C17.4017 26.676 17.656 27.1117 18.3491 26.9741C23.8954 25.1404 27.8934 19.9612 27.8934 13.8423C27.9163 6.1875 21.6537 0 13.9583 0Z"
          fill="#020C87"
        />
      </g>
      <defs>
        <clipPath id="clip0_97_468">
          <rect width="28" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GitHubIcon;
