const Step2Pattern = () => {
  return (
    <svg
      width="639"
      height="921"
      viewBox="0 0 639 921"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          opacity="0.75"
          d="M-18.9477 706.876C24.6129 571.533 -74.6204 466.285 -128.121 385.073C-181.615 303.858 -149.944 185.603 4.9511 202.812C159.857 220.027 327.319 351.297 298.078 600.533C286.237 701.437 219.331 786.606 155.004 819.792C67.9084 864.731 -62.5033 842.208 -18.9427 706.866L-18.9477 706.876Z"
          fill="url(#paint0_linear_98_768)"
        />
        <g opacity="0.75">
          <path
            d="M63.4303 794.84C70.1833 814.12 81.6317 831.258 96.5404 844.419C112.377 858.388 131.316 867.51 152.846 871.543C185.973 877.739 223.824 871.033 262.302 852.138C307.018 830.183 352.465 792.413 397.39 739.861C397.741 739.463 432.327 699.402 457.769 634.878C472.713 596.982 481.611 558.208 484.218 519.619C487.473 471.401 480.862 423.342 464.554 376.782C454.415 347.834 440.345 318.993 422.725 291.078C391.674 241.86 348.189 209.429 296.967 197.284C251.786 186.569 202.982 192.217 159.544 213.184C121.794 231.412 91.5026 259.872 74.241 293.334C55.283 330.094 52.7531 370.914 66.9342 411.402C68.2655 415.203 69.7617 419.058 71.3903 422.849C76.1807 434.025 80.4684 445.016 84.1409 455.501C121.461 562.051 98.0038 621.552 68.3036 696.874L65.9846 702.76C54.1088 732.936 53.2003 765.632 63.4249 794.824L63.4303 794.84ZM62.2597 795.25C52.2976 766.807 52.3836 733.97 64.8346 702.322C95.3131 624.881 121.112 564.809 82.973 455.918C79.3353 445.533 75.1061 434.684 70.2491 423.343C68.5964 419.483 67.1056 415.643 65.7662 411.819C1.19623 227.467 296.027 87.9683 423.769 290.429C442.263 319.738 455.945 348.475 465.719 376.382C537.457 581.2 398.323 740.685 398.323 740.685C233.29 933.693 94.6532 887.736 62.257 795.242L62.2597 795.25Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.75"
          d="M69.2239 136.81C107.426 184.437 159.599 222.251 220.991 229.943C292.088 238.854 335.358 254.085 347.623 357.76C360.12 463.389 297.829 604.438 235.292 663.894C173.268 722.865 65.7387 766.837 -30.4226 717.824C-126.584 668.811 -142.302 581.392 -115.347 518.678C-88.3912 455.965 -77.2261 345.912 -94.2199 232.043C-111.214 118.174 -63.5482 79.8857 -35.278 74.1199C4.87504 65.9294 38.1392 98.0634 69.216 136.811L69.2239 136.81Z"
          fill="url(#paint1_linear_98_768)"
        />
        <path
          opacity="0.75"
          d="M24.4318 428.083C100.081 462.572 140.75 507.772 138.274 571.165C135.499 642.098 117.718 693.061 163.947 751.397C222.01 824.666 349.674 833.224 447.681 747.171C496.073 704.687 548.802 631.111 535.844 536.678C526.323 467.334 463.256 407.963 392.091 394.746C339.637 385.007 275.424 368.421 240.562 305.171C205.698 241.929 141.939 201.009 67.6293 222.686C-14.6007 246.67 -64.5596 387.513 24.4241 428.08L24.4318 428.083Z"
          fill="url(#paint2_linear_98_768)"
        />
        <path
          opacity="0.5"
          d="M442.362 593.228C446.864 606.08 440.094 620.147 427.243 624.648C414.392 629.15 400.324 622.38 395.823 609.529C391.322 596.677 398.091 582.61 410.942 578.109C423.794 573.608 437.861 580.377 442.362 593.228Z"
          fill="url(#paint3_linear_98_768)"
        />
        <path
          opacity="0.5"
          d="M332.903 166.883C339.398 185.425 329.631 205.72 311.09 212.214C292.548 218.709 272.253 208.942 265.759 190.401C259.265 171.859 269.031 151.564 287.572 145.07C306.114 138.576 326.409 148.342 332.903 166.883Z"
          fill="url(#paint4_linear_98_768)"
        />
        <path
          opacity="0.5"
          d="M611.104 598.626C622.384 630.833 605.41 666.09 573.19 677.375C540.97 688.66 505.706 671.7 494.425 639.493C483.145 607.287 500.119 572.03 532.339 560.745C564.559 549.459 599.823 566.42 611.104 598.626Z"
          fill="url(#paint5_linear_98_768)"
        />
        <path
          d="M286.1 833.323C294.561 857.479 281.829 883.923 257.663 892.387C233.497 900.851 207.048 888.131 198.587 863.975C190.126 839.819 202.858 813.375 227.024 804.91C251.19 796.446 277.639 809.167 286.1 833.323Z"
          fill="url(#paint6_linear_98_768)"
        />
        <path
          opacity="0.75"
          d="M305.345 733.946C307.595 740.372 304.211 747.405 297.785 749.656C291.359 751.907 284.326 748.522 282.075 742.096C279.824 735.671 283.209 728.637 289.635 726.386C296.061 724.136 303.094 727.52 305.345 733.946Z"
          fill="url(#paint7_linear_98_768)"
        />
        <path
          opacity="0.3"
          d="M162.543 401.154C168.182 417.255 159.696 434.881 143.588 440.523C127.48 446.165 109.851 437.686 104.211 421.584C98.5717 405.483 107.058 387.857 123.166 382.215C139.274 376.573 156.903 385.052 162.543 401.154Z"
          fill="url(#paint8_linear_98_768)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_98_768"
          x1="-16.5181"
          y1="187.106"
          x2="200.21"
          y2="805.882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_98_768"
          x1="161.346"
          y1="722.371"
          x2="26.9425"
          y2="61.4747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF59D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_98_768"
          x1="487.657"
          y1="705.935"
          x2="-49.9709"
          y2="353.952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF59D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_98_768"
          x1="400.396"
          y1="585.297"
          x2="437.788"
          y2="617.457"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_98_768"
          x1="272.357"
          y1="155.441"
          x2="326.304"
          y2="201.839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_98_768"
          x1="505.898"
          y1="578.764"
          x2="599.599"
          y2="659.382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_98_768"
          x1="262.452"
          y1="806.884"
          x2="222.27"
          y2="890.431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="#FFFDEA" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_98_768"
          x1="299.058"
          y1="726.913"
          x2="288.363"
          y2="749.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D5EDFF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_98_768"
          x1="146.785"
          y1="383.533"
          x2="119.995"
          y2="439.224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="#D5EDFF" stopOpacity="0.66" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Step2Pattern;
