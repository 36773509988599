import "./index.css";

const SkeletonLayout = () => {
  const emptyArray = new Array(14).fill("");
  return (
    <div className="skeleton">
      {emptyArray.map((i) => (
        <div className="items"></div>
      ))}
    </div>
  );
};
export default SkeletonLayout;
