const Step3Pattern = () => {
  return (
    <svg
      width="879"
      height="923"
      viewBox="0 0 879 923"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          opacity="0.75"
          d="M594.614 611.814C460.317 565.131 352.799 661.901 270.37 713.507C187.938 765.105 70.4473 730.705 91.2389 576.25C112.036 421.784 247.149 257.407 495.641 292.411C596.244 306.585 679.84 375.446 711.528 440.523C754.438 528.636 728.902 658.492 594.604 611.809L594.614 611.814Z"
          fill="url(#paint0_linear_98_781)"
        />
        <g opacity="0.75">
          <path
            d="M358.43 794.531C365.183 813.811 376.632 830.949 391.54 844.11C407.377 858.079 426.316 867.201 447.846 871.234C480.973 877.43 518.824 870.724 557.302 851.829C602.018 829.874 647.465 792.104 692.39 739.552C692.741 739.154 727.327 699.093 752.769 634.569C767.713 596.673 776.611 557.899 779.218 519.31C782.473 471.092 775.862 423.033 759.554 376.473C749.415 347.525 735.345 318.684 717.725 290.769C686.674 241.55 643.189 209.12 591.967 196.975C546.786 186.26 497.982 191.908 454.544 212.875C416.794 231.103 386.503 259.563 369.241 293.025C350.283 329.785 347.753 370.605 361.934 411.093C363.265 414.894 364.762 418.749 366.39 422.54C371.181 433.715 375.468 444.706 379.141 455.192C416.461 561.742 393.004 621.243 363.304 696.565L360.985 702.451C349.109 732.627 348.2 765.323 358.425 794.515L358.43 794.531ZM357.26 794.941C347.298 766.498 347.384 733.66 359.835 702.013C390.313 624.572 416.112 564.5 377.973 455.609C374.335 445.224 370.106 434.375 365.249 423.034C363.596 419.174 362.106 415.334 360.766 411.51C296.196 227.158 591.027 87.6592 718.769 290.12C737.263 319.429 750.945 348.166 760.719 376.073C832.457 580.891 693.323 740.376 693.323 740.376C528.29 933.384 389.653 887.427 357.257 794.933L357.26 794.941Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.75"
          d="M364.224 136.498C402.426 184.126 454.599 221.94 515.991 229.632C587.088 238.543 630.358 253.774 642.623 357.449C655.12 463.078 592.829 604.127 530.292 663.583C468.268 722.554 360.739 766.526 264.577 717.513C168.416 668.5 152.698 581.081 179.653 518.367C206.609 455.654 217.774 345.601 200.78 231.732C183.786 117.863 231.452 79.5746 259.722 73.8089C299.875 65.6184 333.139 97.7523 364.216 136.5L364.224 136.498Z"
          fill="url(#paint1_linear_98_781)"
        />
        <path
          opacity="0.75"
          d="M319.432 427.772C395.081 462.261 435.75 507.461 433.274 570.854C430.499 641.787 412.718 692.75 458.947 751.086C517.01 824.355 644.674 832.913 742.681 746.86C791.073 704.375 843.802 630.8 830.844 536.367C821.323 467.023 758.256 407.652 687.091 394.435C634.637 384.696 570.424 368.11 535.562 304.86C500.698 241.618 436.939 200.698 362.629 222.375C280.399 246.359 230.44 387.202 319.424 427.769L319.432 427.772Z"
          fill="url(#paint2_linear_98_781)"
        />
        <path
          opacity="0.5"
          d="M737.362 592.917C741.864 605.769 735.094 619.836 722.243 624.337C709.392 628.839 695.324 622.069 690.823 609.218C686.322 596.366 693.091 582.299 705.942 577.798C718.794 573.297 732.861 580.066 737.362 592.917Z"
          fill="url(#paint3_linear_98_781)"
        />
        <path
          opacity="0.5"
          d="M604.413 188.199C605.538 191.412 603.846 194.929 600.633 196.054C597.42 197.18 593.904 195.487 592.778 192.274C591.653 189.062 593.345 185.545 596.558 184.419C599.771 183.294 603.288 184.986 604.413 188.199Z"
          fill="url(#paint4_linear_98_781)"
        />
        <g opacity="0.5" filter="url(#filter0_d_98_781)">
          <path
            d="M887.215 461.492C898.495 493.698 881.52 528.955 849.3 540.241C817.08 551.526 781.816 534.566 770.536 502.359C759.255 470.153 776.23 434.896 808.45 423.61C840.67 412.325 875.934 429.285 887.215 461.492Z"
            fill="url(#paint5_linear_98_781)"
          />
        </g>
        <path
          d="M552.832 830.737C561.293 854.893 548.561 881.337 524.395 889.802C500.229 898.266 473.78 885.545 465.319 861.389C456.859 837.233 469.59 810.789 493.756 802.325C517.923 793.861 544.372 806.581 552.832 830.737Z"
          fill="url(#paint6_linear_98_781)"
        />
        <path
          opacity="0.75"
          d="M754.274 731.992C756.525 738.418 753.141 745.452 746.715 747.702C740.289 749.953 733.255 746.568 731.005 740.143C728.754 733.717 732.139 726.683 738.565 724.433C744.99 722.182 752.024 725.567 754.274 731.992Z"
          fill="url(#paint7_linear_98_781)"
        />
        <path
          opacity="0.3"
          d="M843.487 657.586C849.127 673.687 840.64 691.313 824.533 696.955C808.425 702.597 790.795 694.118 785.156 678.017C779.516 661.916 788.002 644.29 804.11 638.648C820.218 633.006 837.848 641.485 843.487 657.586Z"
          fill="url(#paint8_linear_98_781)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_98_781"
          x="755.047"
          y="416.117"
          width="153.657"
          height="153.617"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3" dy="11" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_98_781"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_98_781"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_98_781"
          x1="75.0402"
          y1="597.35"
          x2="698.668"
          y2="395.008"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_98_781"
          x1="456.346"
          y1="722.06"
          x2="321.942"
          y2="61.1636"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF59D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_98_781"
          x1="782.657"
          y1="705.624"
          x2="245.029"
          y2="353.641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF59D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_98_781"
          x1="695.396"
          y1="584.986"
          x2="732.788"
          y2="617.146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_98_781"
          x1="593.922"
          y1="186.217"
          x2="603.27"
          y2="194.256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_98_781"
          x1="782.009"
          y1="441.629"
          x2="875.71"
          y2="522.248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_98_781"
          x1="529.184"
          y1="804.298"
          x2="489.002"
          y2="887.845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="#FFFDEA" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_98_781"
          x1="747.987"
          y1="724.96"
          x2="737.293"
          y2="747.178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_98_781"
          x1="827.73"
          y1="639.966"
          x2="800.939"
          y2="695.656"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Step3Pattern;
