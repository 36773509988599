import Step1Pattern from "../assets/patterns/Step1Pattern";
import Step2Pattern from "../assets/patterns/Step2Pattern";
import Step3Pattern from "../assets/patterns/Step3Pattern";
import Step4Pattern from "../assets/patterns/Step4Pattern";

import step1Image from "../assets/step1.png";
import step2Image from "../assets/step2.png";
import step3Image from "../assets/step3.png";
import step4Image from "../assets/step4.png";

export const INSTALL_STEPS = [
  {
    img: step1Image,
    title: "First time set up for users",
    describe: (
      <p>
        Please enter additional information
        <br /> regarding your current position,
        <br />
        your home address, and the time
        <br /> when you will be reporting.
      </p>
    ),
    background: <Step1Pattern />,
    right: true,
  },
  {
    img: step2Image,
    title: "Active Daily report",
    describe: (
      <p>
        Type the word <span style={{ color: "#009CDF" }}>report</span> on
        textfeild.
      </p>
    ),
    background: <Step2Pattern />,
    right: false,
  },
  {
    img: step3Image,
    title: "Your Daily report",
    describe: (
      <p>
        share your yesterday progress,
        <br /> Today's plan and your blockers
        <br /> with your team.
      </p>
    ),
    background: <Step3Pattern />,
    right: true,
  },
  {
    img: step4Image,
    title: "Shared reports with team",
    describe: (
      <p>
        Now, view the reports of your
        <br /> teammates from the channel you
        <br /> selected.
      </p>
    ),
    background: <Step4Pattern />,
    right: false,
  },
];
