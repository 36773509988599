import { useState } from "react";
import selfClearTimeout from "../../utils/selfClearTimeout";
import useUpdateLifecycle from "../../hooks/useUpdateLifeCycle";
import Portal from "../Portal/Portal";
import "./index.css";

const Modal = ({ isOpen, children, onClose }) => {
  const [fadeModal, setFadeModal] = useState(false);
  const [haveToRenderPortal, setHaveToRenderPortal] = useState(isOpen);

  const contentContainerClickHandler = (e) => {
    e.stopPropagation();
  };

  const safeCloseWithAnimateHandler = () => {
    setFadeModal(false);

    selfClearTimeout(() => {
      setHaveToRenderPortal(false);
      onClose?.();
    }, 100);
  };

  useUpdateLifecycle(
    function modalFadeHandler() {
      if (isOpen) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        setHaveToRenderPortal(true);
        selfClearTimeout(() => {
          setFadeModal(true);
        }, 100);
      } else {
        setFadeModal(false);
        selfClearTimeout(() => {
          setHaveToRenderPortal(false);
          document.getElementsByTagName("body")[0].style.overflow = "auto";
        }, 250);
      }
    },
    [isOpen]
  );

  return (
    <Portal renderContainer={haveToRenderPortal}>
      <div className={`${fadeModal && "show-modal"} modal`}>
        <div
          onClick={safeCloseWithAnimateHandler}
          className="content-container"
        >
          <div
            onClick={contentContainerClickHandler}
            className="inner-content"
          >
            <div>{children}</div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
