// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container{
    margin-top: 5rem;
    z-index: 2;
}

.footer-logo{
    margin-left: 3rem;
    margin-bottom: 2rem;
}

.footer{
    background-color:#020C87;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    padding: 2rem;
    color: #fff;
}
.footer a{
    margin: 0 6px;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
}

@media only screen and (max-width: 780px) {
    .footer{
        flex-wrap: wrap;
        justify-content: center;
    }
    .footer p{
        margin: 1rem 0;
        text-align: center;
        line-height: 1.5rem;
    }
    .footer-logo{
        width: 100%;
        justify-content: center;
        display: flex;
        margin: 0;
        margin-bottom: 2rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,aAAa;IACb,WAAW;AACf;AACA;IACI,aAAa;IACb,WAAW;IACX,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;QACf,uBAAuB;IAC3B;IACA;QACI,cAAc;QACd,kBAAkB;QAClB,mBAAmB;IACvB;IACA;QACI,WAAW;QACX,uBAAuB;QACvB,aAAa;QACb,SAAS;QACT,mBAAmB;IACvB;AACJ","sourcesContent":[".footer-container{\n    margin-top: 5rem;\n    z-index: 2;\n}\n\n.footer-logo{\n    margin-left: 3rem;\n    margin-bottom: 2rem;\n}\n\n.footer{\n    background-color:#020C87;\n    display: flex;\n    justify-content: space-between;\n    font-size: 16px;\n    padding: 2rem;\n    color: #fff;\n}\n.footer a{\n    margin: 0 6px;\n    color: #fff;\n    text-decoration: none;\n    font-weight: 700;\n}\n\n@media only screen and (max-width: 780px) {\n    .footer{\n        flex-wrap: wrap;\n        justify-content: center;\n    }\n    .footer p{\n        margin: 1rem 0;\n        text-align: center;\n        line-height: 1.5rem;\n    }\n    .footer-logo{\n        width: 100%;\n        justify-content: center;\n        display: flex;\n        margin: 0;\n        margin-bottom: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
