const MobileActivePattern = () => {
  return (
    <svg
      width="414"
      height="734"
      viewBox="0 0 414 734"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          opacity="0.75"
          d="M-99.5364 558.724C-63.846 447.834 -145.151 361.602 -188.985 295.063C-232.814 228.521 -206.866 131.631 -79.9554 145.731C46.9631 159.835 184.169 267.389 160.212 471.595C150.51 554.268 95.6915 624.049 42.9871 651.239C-28.3728 688.059 -135.223 669.606 -99.5323 558.716L-99.5364 558.724Z"
          fill="url(#paint0_linear_194_1160)"
        />
        <g opacity="0.75">
          <path
            d="M-32.0421 630.795C-26.5092 646.592 -17.1292 660.633 -4.91411 671.416C8.06087 682.862 23.5788 690.336 41.2186 693.64C68.3608 698.717 99.3731 693.222 130.899 677.741C167.536 659.753 204.772 628.807 241.58 585.749C241.867 585.424 270.204 552.6 291.05 499.734C303.294 468.685 310.585 436.916 312.721 405.299C315.387 365.793 309.971 326.417 296.609 288.269C288.302 264.551 276.774 240.921 262.338 218.049C236.896 177.723 201.269 151.152 159.301 141.201C122.283 132.422 82.2965 137.05 46.7062 154.229C15.777 169.163 -9.04172 192.482 -23.1846 219.897C-38.7174 250.016 -40.7902 283.461 -29.1713 316.634C-28.0805 319.748 -26.8546 322.907 -25.5203 326.013C-21.5954 335.169 -18.0823 344.174 -15.0734 352.765C15.5036 440.065 -3.71512 488.815 -28.0493 550.529L-29.9493 555.352C-39.6794 580.075 -40.4238 606.865 -32.0465 630.782L-32.0421 630.795ZM-33.0013 631.131C-41.1635 607.827 -41.093 580.922 -30.8915 554.993C-5.91965 491.543 15.2183 442.325 -16.0303 353.108C-19.0107 344.598 -22.4758 335.71 -26.4553 326.418C-27.8094 323.255 -29.0309 320.109 -30.1282 316.976C-83.0322 165.931 158.53 51.6361 263.193 217.518C278.346 241.531 289.555 265.076 297.564 287.941C356.341 455.754 242.345 586.424 242.345 586.424C107.129 744.561 -6.46039 706.907 -33.0035 631.125L-33.0013 631.131Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.75"
          d="M-27.2951 91.6531C4.00473 130.675 46.7513 161.658 97.0521 167.96C155.303 175.261 190.755 187.74 200.805 272.684C211.044 359.229 160.007 474.794 108.769 523.508C57.9513 571.824 -30.1506 607.851 -108.938 567.694C-187.726 527.536 -200.604 455.912 -178.519 404.529C-156.433 353.146 -147.286 262.977 -161.209 169.681C-175.133 76.3849 -136.079 45.0139 -112.916 40.2899C-80.0179 33.5792 -52.7636 59.9074 -27.3016 91.6545L-27.2951 91.6531Z"
          fill="url(#paint1_linear_194_1160)"
        />
        <path
          opacity="0.75"
          d="M-63.999 330.299C-2.01737 358.557 31.3034 395.591 29.2754 447.53C27.0012 505.648 12.4331 547.403 50.3095 595.199C97.882 655.23 202.481 662.242 282.78 591.737C322.43 556.928 365.631 496.645 355.014 419.274C347.214 362.459 295.542 313.814 237.234 302.985C194.257 295.006 141.646 281.416 113.083 229.594C84.5174 177.778 32.2781 144.252 -28.6061 162.011C-95.9793 181.662 -136.912 297.059 -64.0052 330.297L-63.999 330.299Z"
          fill="url(#paint2_linear_194_1160)"
        />
        <path
          opacity="0.5"
          d="M278.427 465.609C282.115 476.139 276.569 487.665 266.04 491.353C255.51 495.041 243.984 489.494 240.296 478.965C236.608 468.435 242.155 456.91 252.684 453.222C263.214 449.534 274.739 455.08 278.427 465.609Z"
          fill="url(#paint3_linear_194_1160)"
        />
        <path
          opacity="0.5"
          d="M169.498 134.011C170.42 136.644 169.034 139.525 166.401 140.447C163.769 141.369 160.887 139.983 159.965 137.35C159.043 134.718 160.43 131.837 163.062 130.915C165.695 129.993 168.576 131.379 169.498 134.011Z"
          fill="url(#paint4_linear_194_1160)"
        />
        <path
          opacity="0.5"
          d="M429.787 312.233C439.029 338.62 425.121 367.507 398.722 376.754C372.324 386 343.431 372.104 334.188 345.716C324.946 319.328 338.854 290.441 365.253 281.195C391.651 271.949 420.544 285.845 429.787 312.233Z"
          fill="url(#paint5_linear_194_1160)"
        />
        <path
          opacity="0.5"
          d="M105.337 545.743C114.58 572.131 100.672 601.018 74.2731 610.264C47.8744 619.51 18.9816 605.614 9.73921 579.226C0.496825 552.839 14.4048 523.952 40.8035 514.705C67.2021 505.459 96.0949 519.355 105.337 545.743Z"
          fill="url(#paint6_linear_194_1160)"
        />
        <path
          d="M150.397 662.325C157.329 682.117 146.898 703.783 127.098 710.718C107.298 717.653 85.6273 707.23 78.6952 687.439C71.7631 667.647 82.1945 645.981 101.994 639.046C121.794 632.111 143.465 642.533 150.397 662.325Z"
          fill="url(#paint7_linear_194_1160)"
        />
        <path
          opacity="0.75"
          d="M318.555 612.368C320.399 617.633 317.626 623.396 312.361 625.24C307.097 627.084 301.334 624.311 299.49 619.046C297.646 613.781 300.419 608.019 305.684 606.175C310.948 604.331 316.711 607.104 318.555 612.368Z"
          fill="url(#paint8_linear_194_1160)"
        />
        <path
          opacity="0.3"
          d="M382.625 566.911C387.246 580.103 380.293 594.545 367.095 599.167C353.898 603.79 339.453 596.843 334.833 583.651C330.212 570.458 337.165 556.017 350.363 551.394C363.56 546.772 378.005 553.719 382.625 566.911Z"
          fill="url(#paint9_linear_194_1160)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_194_1160"
          x1="-97.5458"
          y1="132.863"
          x2="80.0254"
          y2="639.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" stopOpacity="0.48" />
          <stop offset="1" stopColor="#D5EDFF" stopOpacity="0.33" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_194_1160"
          x1="48.183"
          y1="571.419"
          x2="-61.9374"
          y2="29.9293"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_194_1160"
          x1="315.534"
          y1="557.951"
          x2="-124.959"
          y2="269.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_194_1160"
          x1="244.043"
          y1="459.111"
          x2="274.679"
          y2="485.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_194_1160"
          x1="160.902"
          y1="132.387"
          x2="168.561"
          y2="138.974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_194_1160"
          x1="343.589"
          y1="295.959"
          x2="420.36"
          y2="362.012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_194_1160"
          x1="19.1394"
          y1="529.469"
          x2="95.9111"
          y2="595.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_194_1160"
          x1="131.021"
          y1="640.662"
          x2="98.0993"
          y2="709.115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFFDEA" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_194_1160"
          x1="313.404"
          y1="606.606"
          x2="304.642"
          y2="624.811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_194_1160"
          x1="369.715"
          y1="552.474"
          x2="347.765"
          y2="598.103"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default MobileActivePattern;
