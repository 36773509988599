import Information from "../components/information";
import Installation from "../components/installation";
import Introduction from "../components/introduction";
import Footer from "../components/footer";
// import "./app.css";

function Home() {
  return (
    <div id="app">
      <Introduction />
      <Information />
      <Installation />
      <Footer />
    </div>
  );
}

export default Home;
