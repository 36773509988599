// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select{
    margin-top: 2rem;
    background-color: #fff;
    width: 600px;
    max-height: 500px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid #D4EDFF;
}

.select .items{
    padding: 1rem ;
    cursor: pointer;
    transition: 0.2;
    color: #2C3131;
}

.select .items:hover{
    background-color:#EBF7FD ;
}

.select .active{
    background-color:#EBF7FD  !important;

}


::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #E8E8E8;
    border-radius: 1rem;
  }

  @media only screen and (max-width: 780px) {
    .select{  
        width: 280px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/components/addPage/Select/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;;AAExC;;;AAGA;IACI,WAAW;EACb;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE;QACI,YAAY;IAChB;;AAEJ","sourcesContent":[".select{\n    margin-top: 2rem;\n    background-color: #fff;\n    width: 600px;\n    max-height: 500px;\n    overflow: auto;\n    border-radius: 8px;\n    border: 1px solid #D4EDFF;\n}\n\n.select .items{\n    padding: 1rem ;\n    cursor: pointer;\n    transition: 0.2;\n    color: #2C3131;\n}\n\n.select .items:hover{\n    background-color:#EBF7FD ;\n}\n\n.select .active{\n    background-color:#EBF7FD  !important;\n\n}\n\n\n::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: transparent;\n  }\n  \n  ::-webkit-scrollbar-thumb {\n    background: #E8E8E8;\n    border-radius: 1rem;\n  }\n\n  @media only screen and (max-width: 780px) {\n    .select{  \n        width: 280px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
