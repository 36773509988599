import axios from "axios";

const api = axios.create({
  baseURL: `https://app.kianbot.com/slack_app/`,
  timeout: 15000,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: false,
});

export const services = {
  getChannel(workspaceId) {
    return api.get(`get_channels/?workspace_id=${workspaceId}`);
  },
  getUserChannel(channelId) {
    return api.get(`get_user_channel/?channel_id=${channelId}`);
  },
  postUsers(param) {
    return api.post("add_user_report/", param);
  },
};
