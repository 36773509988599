const Pattern = () => {
  return (
    <svg
      width="1211"
      height="995"
      viewBox="0 0 1211 995"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.75">
        <path
          d="M168.963 264.918C140.951 273.758 115.807 289.442 96.2248 310.277C75.4387 332.409 61.4812 359.218 54.7213 389.977C44.3341 437.306 52.2792 492.001 77.7143 548.144C107.269 613.388 159.52 680.392 233.041 747.309C233.597 747.831 289.628 799.329 381.225 838.785C435.022 861.959 490.353 876.485 545.698 881.962C614.852 888.8 684.221 881.454 751.869 860.106C793.926 846.833 836.007 827.906 876.917 803.837C949.045 761.42 997.601 700.384 1017.35 627.318C1034.77 562.87 1028.83 492.482 1000.64 429.117C976.134 374.051 936.585 329.244 889.265 302.937C837.28 274.045 778.725 268.58 719.899 287.144C714.377 288.886 708.769 290.864 703.248 293.034C686.971 299.417 670.983 305.086 655.749 309.894C500.94 358.748 416.473 322.372 309.546 276.313L301.189 272.717C258.351 254.298 211.399 251.526 168.985 264.911L168.963 264.918ZM168.426 263.217C209.751 250.176 256.942 251.772 301.871 271.084C411.808 318.356 496.992 358.124 655.201 308.197C670.29 303.435 686.071 297.844 702.588 291.372C708.21 289.17 713.795 287.2 719.351 285.447C987.199 200.919 1174.5 630.868 877.803 805.366C834.851 830.63 792.937 849.003 752.391 861.799C454.809 955.71 231.815 748.613 231.815 748.613C-38.202 502.795 34.0525 305.623 168.437 263.214L168.426 263.217Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.75"
        d="M1114.47 302.731C1044.3 355.496 987.618 428.777 973.813 516.657C957.822 618.426 933.993 679.925 784.436 692.908C632.059 706.137 432.125 610.306 349.472 517.775C267.493 426.003 209.111 269.509 283.862 133.516C358.614 -2.47775 484.961 -21.1506 573.889 20.3939C662.818 61.9384 820.492 82.9113 984.912 63.5896C1149.33 44.2678 1202.23 114.48 1209.25 155.364C1219.22 213.432 1171.55 259.796 1114.47 302.719L1114.47 302.731Z"
        fill="url(#paint0_linear_0_1)"
      />
      <path
        opacity="0.75"
        d="M332.101 681.736C448.893 656.195 534.667 673.137 586.483 748.208C644.454 832.213 667.939 906.178 771.439 933.439C901.433 967.679 1055.72 867.456 1094.29 683.949C1113.34 593.346 1110.58 463.233 1014.24 365.755C943.484 294.18 819.716 280.245 726.428 326.394C657.67 360.414 569.477 396.694 474.82 353.979C380.168 311.275 271.514 319.166 204.695 408.173C130.753 506.665 194.714 711.785 332.09 681.74L332.101 681.736Z"
        fill="url(#paint1_linear_0_1)"
      />
      <path
        opacity="0.5"
        d="M441.763 818.509C423.091 824.402 403.176 814.045 397.282 795.375C391.388 776.705 401.747 756.793 420.419 750.9C439.092 745.007 459.007 755.365 464.901 774.034C470.795 792.704 460.436 812.616 441.763 818.509Z"
        fill="url(#paint2_linear_0_1)"
      />
      <path
        opacity="0.75"
        d="M241.119 836.585C231.783 839.531 221.825 834.353 218.878 825.018C215.931 815.683 221.111 805.727 230.447 802.78C239.783 799.834 249.741 805.013 252.688 814.347C255.635 823.682 250.455 833.638 241.119 836.585Z"
        fill="url(#paint3_linear_0_1)"
      />
      <path
        opacity="0.3"
        d="M344.064 968.12C320.67 975.503 295.717 962.518 288.33 939.118C280.942 915.717 293.918 890.762 317.312 883.379C340.707 875.996 365.66 888.981 373.047 912.381C380.435 935.781 367.459 960.736 344.064 968.12Z"
        fill="url(#paint4_linear_0_1)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_1"
          x1="268.738"
          y1="408.892"
          x2="1224.63"
          y2="245.296"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF59D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="1104.74"
          y1="602.039"
          x2="182.562"
          y2="660.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9EAAE" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_1"
          x1="455.041"
          y1="758.557"
          x2="407.153"
          y2="810.858"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF59D" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_1"
          x1="251.508"
          y1="827.865"
          x2="220.056"
          y2="811.498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F9EAAE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_1"
          x1="370.095"
          y1="946.264"
          x2="291.258"
          y2="905.261"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F9EAAE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Pattern;
