const CirclePattern = () => (
  <svg
    width="1575"
    height="1007"
    viewBox="0 0 1575 1007"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        opacity="0.75"
        d="M65.9545 755.742C119.372 608.755 13.547 489.306 -42.5693 398.028C-98.677 306.747 -59.0788 178.07 111.747 202.962C282.585 227.86 462.948 378.532 421.016 651.182C404.036 761.566 326.697 852.535 254.207 886.503C156.059 932.5 12.5428 902.718 65.9604 755.731L65.9545 755.742Z"
        fill="url(#paint0_linear_25_457)"
      />
      <g opacity="0.75">
        <path
          d="M267.571 871.106C274.339 892.576 286.376 911.881 302.384 926.956C319.388 942.957 339.999 953.756 363.659 959.066C400.064 967.226 442.16 961.394 485.391 942.189C535.631 919.874 587.253 880.203 638.834 824.261C639.236 823.839 678.931 781.207 709.404 711.311C727.302 670.261 738.548 627.998 742.83 585.683C748.177 532.81 742.61 479.71 726.267 427.862C716.106 395.628 701.594 363.35 683.124 331.945C650.575 276.576 603.669 239.154 547.471 223.718C497.902 210.1 443.734 214.323 394.942 235.604C352.54 254.105 318.014 284.159 297.714 320.24C275.418 359.879 271.14 404.65 285.352 449.735C286.687 453.968 288.201 458.266 289.864 462.501C294.756 474.981 299.098 487.238 302.779 498.914C340.18 617.564 312.085 682.018 276.513 763.611L273.735 769.987C259.51 802.676 257.319 838.582 267.566 871.089L267.571 871.106ZM266.262 871.509C256.278 839.837 257.564 803.742 272.48 769.458C308.989 685.568 339.695 620.581 301.472 499.325C297.827 487.76 293.544 475.662 288.585 462.997C286.897 458.687 285.388 454.405 284.046 450.147C219.335 244.86 550.397 103.51 684.301 331.274C703.688 364.247 717.774 396.394 727.57 427.47C799.464 655.547 639.835 825.205 639.835 825.205C450.354 1030.66 298.726 974.497 266.259 871.501L266.262 871.509Z"
          fill="white"
        />
      </g>
      <path
        opacity="0.75"
        d="M185.434 132.944C225.901 186.738 282.212 230.294 349.885 241.117C428.255 253.653 475.575 272.057 485.177 386.416C494.961 502.931 420.588 655.462 349.072 718.356C278.144 780.737 157.41 824.882 52.829 767.324C-51.7521 709.767 -65.8006 613.132 -33.5526 545.284C-1.30453 477.437 15.2777 356.978 0.831271 231.238C-13.6151 105.498 40.6245 65.2815 72.1438 60.0408C116.912 52.5959 152.506 89.1804 185.425 132.945L185.434 132.944Z"
        fill="url(#paint1_linear_25_457)"
      />
      <path
        opacity="0.75"
        d="M475.359 732.139C455.993 642.787 469.276 577.274 527.256 537.899C592.137 493.844 649.189 476.121 670.471 397.091C697.201 297.831 620.394 179.589 479.108 149.538C409.349 134.697 309.091 136.413 233.728 209.754C178.39 263.615 167.321 358.178 202.629 429.628C228.657 482.29 256.373 549.816 223.208 622.038C190.051 694.257 195.839 777.335 264.24 828.684C339.928 885.507 498.143 837.248 475.362 732.147L475.359 732.139Z"
        fill="url(#paint2_linear_25_457)"
      />
      <path
        opacity="0.5"
        d="M1489.52 784.744C1494.01 799.035 1485.98 814.226 1471.58 818.674C1457.18 823.122 1441.86 815.142 1437.37 800.851C1432.88 786.559 1440.92 771.368 1455.32 766.921C1469.72 762.473 1485.03 770.452 1489.52 784.744Z"
        fill="url(#paint3_linear_25_457)"
      />
      <path
        opacity="0.5"
        d="M1269.38 210.835C1270.5 214.408 1268.49 218.205 1264.89 219.317C1261.29 220.429 1257.46 218.434 1256.34 214.861C1255.22 211.289 1257.23 207.491 1260.83 206.379C1264.43 205.267 1268.26 207.262 1269.38 210.835Z"
        fill="url(#paint4_linear_25_457)"
      />
      <g opacity="0.5" filter="url(#filter0_d_25_457)">
        <path
          d="M1510.15 505.757C1475.83 521.474 1435.15 506.595 1419.29 472.524C1403.42 438.452 1418.38 398.091 1452.69 382.374C1487.01 366.657 1527.69 381.537 1543.55 415.608C1559.42 449.679 1544.46 490.04 1510.15 505.757Z"
          fill="url(#paint5_linear_25_457)"
        />
      </g>
      <path
        d="M486.825 295.604C495.265 322.466 480.156 351.022 453.076 359.386C425.997 367.75 397.203 352.754 388.762 325.891C380.322 299.029 395.431 270.473 422.511 262.109C449.59 253.746 478.384 268.742 486.825 295.604Z"
        fill="url(#paint6_linear_25_457)"
      />
      <path
        opacity="0.75"
        d="M707.394 817.855C709.639 825.001 705.623 832.596 698.422 834.82C691.222 837.044 683.565 833.054 681.319 825.908C679.074 818.763 683.091 811.167 690.291 808.943C697.492 806.719 705.149 810.709 707.394 817.855Z"
        fill="url(#paint7_linear_25_457)"
      />
      <path
        opacity="0.3"
        d="M809.018 739.57C814.644 757.475 804.572 776.51 786.522 782.084C768.473 787.659 749.28 777.664 743.654 759.759C738.028 741.853 748.099 722.819 766.149 717.245C784.199 711.67 803.392 721.665 809.018 739.57Z"
        fill="url(#paint8_linear_25_457)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_25_457"
        x="1400.95"
        y="372.11"
        width="166.938"
        height="165.911"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="3" dy="11" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_25_457"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_25_457"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_25_457"
        x1="88.581"
        y1="184.88"
        x2="301.424"
        y2="874.009"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#FFF59D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_25_457"
        x1="264.963"
        y1="779.733"
        x2="143.749"
        y2="48.1852"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF59D" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_25_457"
        x1="416.025"
        y1="141.304"
        x2="457.63"
        y2="846.441"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF59D" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_25_457"
        x1="1443.37"
        y1="774.409"
        x2="1483.24"
        y2="811.49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEDCA1" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_25_457"
        x1="1257.84"
        y1="208.251"
        x2="1267.81"
        y2="217.521"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEDCA1" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_25_457"
        x1="1520.88"
        y1="388.565"
        x2="1442.73"
        y2="500.095"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEDCA1" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_25_457"
        x1="461.658"
        y1="265.646"
        x2="414.52"
        y2="356.157"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#FFFDEA" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_25_457"
        x1="700.703"
        y1="809.887"
        x2="688.159"
        y2="833.957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#FFF59D" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_25_457"
        x1="792.248"
        y1="719.605"
        x2="760.82"
        y2="779.937"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#FFF59D" />
      </linearGradient>
    </defs>
  </svg>
);

export default CirclePattern;
