// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton{
    margin-top: 2rem;
    background-color: #fff;
    width: 600px;
    max-height: 500px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid #D4EDFF;
    overflow: hidden;
}
.skeleton .items{
    height: 50px;
    margin: 1rem ;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: 0.2;
    background-color: rgb(216, 218, 218);
    color: #2C3131;
    display: flex;
    align-items: center;
    position: relative;
}

.skeleton .items::after{
    content: "";
    width: 100%;
    height: 100%;
    animation: animateFade 1500ms  ease-in-out infinite backwards;
    position: absolute;
    transform: translate(-50%,-50%);
    left: -50%;
    top: 50%;
    background: rgb(255, 255, 255);
    background: linear-gradient( 90deg, rgba(255, 255, 255, 0) 0%, hwb(0deg 97% 3%) 50%, rgba(255, 255, 255, 0) 100% );       
}


@keyframes animateFade {
	from {
			left: -50%;
		}
		to {
			left: 150%;
		}
}

@media only screen and (max-width: 780px) {
    .skeleton{
        width: 280px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/skeletonLayout/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,oCAAoC;IACpC,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,YAAY;IACZ,6DAA6D;IAC7D,kBAAkB;IAClB,+BAA+B;IAC/B,UAAU;IACV,QAAQ;IACR,8BAA8B;IAC9B,kHAAkH;AACtH;;;AAGA;CACC;GACE,UAAU;EACX;EACA;GACC,UAAU;EACX;AACF;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".skeleton{\n    margin-top: 2rem;\n    background-color: #fff;\n    width: 600px;\n    max-height: 500px;\n    overflow: auto;\n    border-radius: 8px;\n    border: 1px solid #D4EDFF;\n    overflow: hidden;\n}\n.skeleton .items{\n    height: 50px;\n    margin: 1rem ;\n    border-radius: 0.4rem;\n    cursor: pointer;\n    transition: 0.2;\n    background-color: rgb(216, 218, 218);\n    color: #2C3131;\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.skeleton .items::after{\n    content: \"\";\n    width: 100%;\n    height: 100%;\n    animation: animateFade 1500ms  ease-in-out infinite backwards;\n    position: absolute;\n    transform: translate(-50%,-50%);\n    left: -50%;\n    top: 50%;\n    background: rgb(255, 255, 255);\n    background: linear-gradient( 90deg, rgba(255, 255, 255, 0) 0%, hwb(0deg 97% 3%) 50%, rgba(255, 255, 255, 0) 100% );       \n}\n\n\n@keyframes animateFade {\n\tfrom {\n\t\t\tleft: -50%;\n\t\t}\n\t\tto {\n\t\t\tleft: 150%;\n\t\t}\n}\n\n@media only screen and (max-width: 780px) {\n    .skeleton{\n        width: 280px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
