import appImage from "../../assets/laptopImage.png";
import mobileImage from "../../assets/mobileSpace.png";

import "./index.css";
import CirclePattern from "../../assets/patterns/CirclePattern";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import MobilePattern from "../../assets/patterns/MobilePattern";
import AddSlackBtn from "../addSlackBtn";

const Information = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="information_container">
      <p className="title" data-aos="fade-up">
        Fully Automated Daily Bot
      </p>
      <div className="description">
        <p data-aos="fade-up">
          Kianbot automates the process of reporting team members through a form
          in Slack. Daily performance reports
        </p>
      </div>
      <div className="information-btn-container" data-aos="fade-up">
        <AddSlackBtn isDark />
      </div>
      <div className="app-screen">
        <img src={appImage} alt="laptop-background" data-aos="fade-up" />
      </div>
      <div className="mobile-screen">
        <img src={mobileImage} alt="mobile-background" data-aos="fade-up" />
      </div>
      <div className="circle-pattern">
        <CirclePattern />
      </div>
      <div className="circle-pattern-mobile">
        <MobilePattern />
      </div>
    </div>
  );
};
export default Information;
