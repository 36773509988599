import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import useUniqueId from "../../hooks/useUniqueId";

const Portal = ({ children, renderContainer, className }) => {
  const [portalContainer, setPortalContainer] = useState();
  const portalContainerId = useUniqueId();

  function createPortalContainerElement() {
    const element = document.createElement("div");
    element.id = portalContainerId;
    if (className) element.className = className;
    setPortalContainer(element);
    return element;
  }

  function appendPortalContainer(element) {
    document.body.appendChild(element);
  }

  function removePortalContainer() {
    document.getElementById(portalContainerId)?.remove();
    setPortalContainer(null);
  }

  useEffect(
    function createAndAppendPortalContainerHandler() {
      if (renderContainer) {
        appendPortalContainer(createPortalContainerElement());
      } else removePortalContainer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renderContainer]
  );

  if (renderContainer && portalContainer)
    return createPortal(children, document.getElementById(portalContainerId));
  else return null;
};

export default Portal;
