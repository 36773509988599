// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-btn{
        cursor: pointer;
        border: none;
        border-radius: 1rem;
        box-shadow: 1px 3px 20px -8px #595454;
        padding: 0.2rem 1.5rem;
        display: flex;
        align-items: center;
        background-color: #fff;
        font-weight: bold;
        justify-content: space-between;
        font-size: 16px;
        transition: 0.3s;
        font-family: inherit;
}    
    
 .action-btn img{
        height: 25px;
        margin-right:1rem;
}

.dark{
    background-color:  #020C87;
    color: #fff;
}

.action-btn:hover{
        transform: translate(0px , -5px);
}`, "",{"version":3,"sources":["webpack://./src/components/addSlackBtn/index.css"],"names":[],"mappings":"AAAA;QACQ,eAAe;QACf,YAAY;QACZ,mBAAmB;QACnB,qCAAqC;QACrC,sBAAsB;QACtB,aAAa;QACb,mBAAmB;QACnB,sBAAsB;QACtB,iBAAiB;QACjB,8BAA8B;QAC9B,eAAe;QACf,gBAAgB;QAChB,oBAAoB;AAC5B;;CAEC;QACO,YAAY;QACZ,iBAAiB;AACzB;;AAEA;IACI,0BAA0B;IAC1B,WAAW;AACf;;AAEA;QACQ,gCAAgC;AACxC","sourcesContent":[".action-btn{\n        cursor: pointer;\n        border: none;\n        border-radius: 1rem;\n        box-shadow: 1px 3px 20px -8px #595454;\n        padding: 0.2rem 1.5rem;\n        display: flex;\n        align-items: center;\n        background-color: #fff;\n        font-weight: bold;\n        justify-content: space-between;\n        font-size: 16px;\n        transition: 0.3s;\n        font-family: inherit;\n}    \n    \n .action-btn img{\n        height: 25px;\n        margin-right:1rem;\n}\n\n.dark{\n    background-color:  #020C87;\n    color: #fff;\n}\n\n.action-btn:hover{\n        transform: translate(0px , -5px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
