const Step4Pattern = () => {
  return (
    <svg
      width="753"
      height="922"
      viewBox="0 0 753 922"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          opacity="0.75"
          d="M73.0523 707.561C116.613 572.219 17.3796 466.97 -36.1213 385.758C-89.6146 304.544 -57.9444 186.288 96.9511 203.497C251.857 220.712 419.319 351.982 390.078 601.218C378.237 702.123 311.331 787.291 247.004 820.477C159.908 865.416 29.4967 842.893 73.0573 707.551L73.0523 707.561Z"
          fill="url(#paint0_linear_102_2560)"
        />
        <g opacity="0.75">
          <path
            d="M155.43 795.525C162.183 814.805 173.632 831.943 188.54 845.104C204.377 859.073 223.316 868.195 244.846 872.228C277.973 878.424 315.824 871.718 354.302 852.823C399.018 830.868 444.465 793.098 489.39 740.546C489.741 740.148 524.327 700.087 549.769 635.563C564.713 597.667 573.611 558.893 576.218 520.304C579.473 472.086 572.862 424.027 556.554 377.467C546.415 348.52 532.345 319.678 514.725 291.763C483.674 242.545 440.189 210.114 388.967 197.969C343.786 187.254 294.982 192.902 251.544 213.869C213.794 232.097 183.503 260.557 166.241 294.019C147.283 330.779 144.753 371.599 158.934 412.087C160.265 415.888 161.762 419.743 163.39 423.534C168.181 434.71 172.468 445.701 176.141 456.186C213.461 562.736 190.004 622.237 160.304 697.559L157.985 703.446C146.109 733.621 145.2 766.317 155.425 795.509L155.43 795.525ZM154.26 795.935C144.298 767.492 144.384 734.655 156.835 703.007C187.313 625.566 213.112 565.494 174.973 456.603C171.335 446.218 167.106 435.369 162.249 424.028C160.596 420.168 159.106 416.328 157.766 412.504C93.1962 228.152 388.027 88.6534 515.769 291.114C534.263 320.423 547.945 349.16 557.719 377.067C629.457 581.885 490.323 741.37 490.323 741.37C325.29 934.378 186.653 888.421 154.257 795.927L154.26 795.935Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.75"
          d="M161.224 137.495C199.426 185.122 251.599 222.937 312.991 230.628C384.088 239.539 427.358 254.77 439.623 358.445C452.12 464.074 389.829 605.123 327.292 664.579C265.268 723.55 157.739 767.522 61.5774 718.509C-34.5839 669.496 -50.3019 582.077 -23.3466 519.363C3.60884 456.65 14.7739 346.597 -2.21993 232.728C-19.2138 118.859 28.4518 80.5707 56.722 74.805C96.875 66.6144 130.139 98.7484 161.216 137.496L161.224 137.495Z"
          fill="url(#paint1_linear_102_2560)"
        />
        <path
          opacity="0.75"
          d="M116.432 428.768C192.081 463.257 232.75 508.458 230.274 571.85C227.499 642.783 209.718 693.746 255.947 752.082C314.01 825.351 441.674 833.91 539.681 747.856C588.073 705.372 640.802 631.796 627.844 537.363C618.323 468.019 555.256 408.648 484.091 395.431C431.637 385.692 367.424 369.106 332.562 305.856C297.698 242.614 233.939 201.695 159.629 223.371C77.3993 247.355 27.4404 388.198 116.424 428.765L116.432 428.768Z"
          fill="url(#paint2_linear_102_2560)"
        />
        <path
          opacity="0.5"
          d="M534.362 593.913C538.864 606.765 532.094 620.832 519.243 625.333C506.392 629.835 492.324 623.065 487.823 610.214C483.322 597.363 490.091 583.295 502.942 578.794C515.794 574.293 529.861 581.062 534.362 593.913Z"
          fill="url(#paint3_linear_102_2560)"
        />
        <path
          opacity="0.5"
          d="M401.413 189.193C402.538 192.406 400.846 195.923 397.633 197.048C394.42 198.174 390.903 196.481 389.778 193.269C388.652 190.056 390.345 186.539 393.558 185.414C396.771 184.288 400.287 185.981 401.413 189.193Z"
          fill="url(#paint4_linear_102_2560)"
        />
        <path
          opacity="0.5"
          d="M719.104 406.718C730.384 438.924 713.41 474.181 681.19 485.466C648.97 496.751 613.706 479.791 602.425 447.585C591.145 415.378 608.119 380.121 640.339 368.836C672.559 357.551 707.823 374.511 719.104 406.718Z"
          fill="url(#paint5_linear_102_2560)"
        />
        <path
          opacity="0.5"
          d="M323.104 691.718C334.384 723.924 317.41 759.181 285.19 770.466C252.97 781.751 217.706 764.791 206.425 732.585C195.145 700.378 212.119 665.121 244.339 653.836C276.559 642.551 311.823 659.511 323.104 691.718Z"
          fill="url(#paint6_linear_102_2560)"
        />
        <path
          opacity="0.5"
          d="M26.2309 332.39C30.2247 347.296 21.3743 362.618 6.46289 366.613C-8.4485 370.609 -23.7742 361.764 -27.768 346.859C-31.7619 331.954 -22.9114 316.632 -8.00005 312.637C6.91133 308.641 22.237 317.485 26.2309 332.39Z"
          fill="url(#paint7_linear_102_2560)"
        />
        <path
          d="M378.1 834.008C386.561 858.164 373.829 884.608 349.663 893.072C325.497 901.536 299.048 888.816 290.587 864.66C282.126 840.504 294.858 814.06 319.024 805.596C343.19 797.131 369.639 809.852 378.1 834.008Z"
          fill="url(#paint8_linear_102_2560)"
        />
        <path
          opacity="0.75"
          d="M583.345 773.037C585.595 779.463 582.211 786.497 575.785 788.747C569.359 790.998 562.326 787.613 560.075 781.188C557.824 774.762 561.209 767.728 567.635 765.478C574.061 763.227 581.094 766.612 583.345 773.037Z"
          fill="url(#paint9_linear_102_2560)"
        />
        <path
          opacity="0.3"
          d="M661.543 717.556C667.182 733.657 658.696 751.283 642.588 756.925C626.48 762.567 608.851 754.088 603.211 737.987C597.572 721.886 606.058 704.259 622.166 698.618C638.274 692.976 655.903 701.455 661.543 717.556Z"
          fill="url(#paint10_linear_102_2560)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_102_2560"
          x1="75.4819"
          y1="187.791"
          x2="292.21"
          y2="806.567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" stopOpacity="0.48" />
          <stop offset="1" stopColor="#D5EDFF" stopOpacity="0.33" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_102_2560"
          x1="253.346"
          y1="723.056"
          x2="118.942"
          y2="62.1597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_102_2560"
          x1="579.657"
          y1="706.62"
          x2="42.0291"
          y2="354.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_102_2560"
          x1="492.396"
          y1="585.983"
          x2="529.788"
          y2="618.142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_102_2560"
          x1="390.921"
          y1="187.211"
          x2="400.269"
          y2="195.251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_102_2560"
          x1="613.898"
          y1="386.855"
          x2="707.599"
          y2="467.473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_102_2560"
          x1="217.898"
          y1="671.855"
          x2="311.599"
          y2="752.473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_102_2560"
          x1="-20.5342"
          y1="319.865"
          x2="18.9818"
          y2="359.396"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_102_2560"
          x1="354.452"
          y1="807.569"
          x2="314.27"
          y2="891.116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFFDEA" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_102_2560"
          x1="577.058"
          y1="766.005"
          x2="566.363"
          y2="788.223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_102_2560"
          x1="645.785"
          y1="699.935"
          x2="618.995"
          y2="755.626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Step4Pattern;
