// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal{
    opacity: 0;
    transition: 0.3s;
    position:fixed;
    z-index: 5;
    height: 100%;
    width: 100%;
    background-color: #0000007f;
    top: 0;
}
.show-modal{
    opacity: 1;
}

.modal .content-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal .inner-content{
    background-color: #fff;
    border-radius: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/index.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,cAAc;IACd,UAAU;IACV,YAAY;IACZ,WAAW;IACX,2BAA2B;IAC3B,MAAM;AACV;AACA;IACI,UAAU;AACd;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[".modal{\n    opacity: 0;\n    transition: 0.3s;\n    position:fixed;\n    z-index: 5;\n    height: 100%;\n    width: 100%;\n    background-color: #0000007f;\n    top: 0;\n}\n.show-modal{\n    opacity: 1;\n}\n\n.modal .content-container{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.modal .inner-content{\n    background-color: #fff;\n    border-radius: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
