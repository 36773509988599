import slack from "../../assets/slack.png";
import { BTN_URL } from "../../static/introStatic";
import "./index.css";

const AddSlackBtn = ({ isDark }) => {
  function onAddToSlack() {
    window.location.href = BTN_URL;
  }

  return (
    <button
      className={`action-btn ${isDark && "dark"}`}
      onClick={onAddToSlack}
    >
      <img src={slack} alt="slack" />
      <p>Add to Slack</p>
    </button>
  );
};
export default AddSlackBtn;
