export const KIAN_OPTIONS = [
  "Free daily standup for Slack workspace",
  "Immediate setup & Start",
  "Various Time zone Friendly",
];

export const BTN_URL =
  "https://slack.com/oauth/v2/authorize?client_id=721157103719.4414297400930&scope=app_mentions:read,channels:history,channels:read,chat:write,chat:write.customize,groups:read,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,reactions:read,team:read,users.profile:read,users:read,users:write,files:write&user_scope=";

export const GITHUB_URL = "https://github.com/DataCoLabltd/Kian_dailyBot";
