import { useParams } from "react-router";
import AddPage from "../components/addPage/AddPage";
import { useEffect, useState } from "react";
import { services } from "../api/servisec";

const Activate = () => {
  const params = useParams();

  const [channels, setChannels] = useState([]);

  useEffect(() => {
    if (params.workspace_id) {
      services
        .getChannel(params.workspace_id)
        .then((res) => {
          setChannels(res.data.channels_info);
        })
        .catch((error) => {
          console.error("Error fetching channels:", error);
        });
    }
  }, []);

  return <AddPage channels={channels} />;
};
export default Activate;
