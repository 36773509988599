const Step1Pattern = () => {
  return (
    <svg
      width="881"
      height="922"
      viewBox="0 0 881 922"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          opacity="0.75"
          d="M208.053 707.561C251.613 572.219 152.38 466.971 98.8789 385.758C45.3857 304.544 77.0558 186.288 231.951 203.497C386.857 220.712 554.319 351.983 525.079 601.219C513.237 702.123 446.331 787.291 382.004 820.477C294.909 865.416 164.497 842.894 208.057 707.551L208.053 707.561Z"
          fill="url(#paint0_linear_98_755)"
        />
        <g opacity="0.75">
          <path
            d="M290.43 795.525C297.183 814.805 308.632 831.943 323.54 845.104C339.377 859.073 358.316 868.195 379.846 872.228C412.973 878.424 450.824 871.718 489.302 852.823C534.018 830.868 579.465 793.098 624.39 740.546C624.741 740.148 659.327 700.087 684.769 635.563C699.713 597.667 708.611 558.893 711.218 520.304C714.473 472.086 707.862 424.027 691.554 377.467C681.415 348.52 667.345 319.678 649.725 291.763C618.674 242.545 575.189 210.114 523.967 197.969C478.786 187.254 429.982 192.902 386.544 213.869C348.794 232.097 318.503 260.557 301.241 294.019C282.283 330.779 279.753 371.599 293.934 412.087C295.265 415.888 296.762 419.743 298.39 423.534C303.181 434.71 307.468 445.701 311.141 456.186C348.461 562.736 325.004 622.237 295.304 697.559L292.985 703.446C281.109 733.621 280.2 766.317 290.425 795.509L290.43 795.525ZM289.26 795.935C279.298 767.492 279.384 734.655 291.835 703.007C322.313 625.566 348.112 565.494 309.973 456.603C306.335 446.218 302.106 435.369 297.249 424.028C295.596 420.168 294.106 416.328 292.766 412.504C228.196 228.152 523.027 88.6534 650.769 291.114C669.263 320.423 682.945 349.16 692.719 377.067C764.457 581.885 625.323 741.37 625.323 741.37C460.29 934.378 321.653 888.421 289.257 795.927L289.26 795.935Z"
            fill="white"
          />
        </g>
        <path
          opacity="0.75"
          d="M296.224 137.495C334.426 185.122 386.599 222.937 447.991 230.628C519.088 239.539 562.358 254.77 574.623 358.445C587.12 464.074 524.829 605.123 462.292 664.579C400.268 723.55 292.739 767.522 196.577 718.509C100.416 669.496 84.6981 582.077 111.653 519.363C138.609 456.65 149.774 346.597 132.78 232.728C115.786 118.859 163.452 80.5707 191.722 74.805C231.875 66.6144 265.139 98.7484 296.216 137.496L296.224 137.495Z"
          fill="url(#paint1_linear_98_755)"
        />
        <path
          opacity="0.75"
          d="M251.432 428.768C327.081 463.257 367.75 508.458 365.274 571.85C362.499 642.783 344.718 693.746 390.947 752.082C449.01 825.351 576.674 833.91 674.681 747.856C723.073 705.372 775.802 631.796 762.844 537.363C753.323 468.019 690.256 408.648 619.091 395.431C566.637 385.692 502.424 369.106 467.562 305.856C432.698 242.614 368.939 201.695 294.629 223.371C212.399 247.355 162.44 388.198 251.424 428.765L251.432 428.768Z"
          fill="url(#paint2_linear_98_755)"
        />
        <path
          opacity="0.5"
          d="M669.362 593.913C673.864 606.765 667.094 620.832 654.243 625.333C641.392 629.835 627.324 623.065 622.823 610.214C618.322 597.363 625.091 583.295 637.942 578.794C650.794 574.293 664.861 581.062 669.362 593.913Z"
          fill="url(#paint3_linear_98_755)"
        />
        <path
          opacity="0.5"
          d="M536.413 189.194C537.538 192.407 535.846 195.923 532.633 197.049C529.42 198.174 525.903 196.482 524.778 193.269C523.652 190.056 525.345 186.539 528.558 185.414C531.771 184.289 535.287 185.981 536.413 189.194Z"
          fill="url(#paint4_linear_98_755)"
        />
        <path
          opacity="0.5"
          d="M854.104 406.718C865.384 438.924 848.41 474.181 816.19 485.466C783.97 496.751 748.706 479.791 737.425 447.585C726.145 415.378 743.119 380.121 775.339 368.836C807.559 357.551 842.823 374.511 854.104 406.718Z"
          fill="url(#paint5_linear_98_755)"
        />
        <path
          opacity="0.5"
          d="M458.104 691.718C469.384 723.924 452.41 759.181 420.19 770.466C387.97 781.751 352.706 764.791 341.425 732.585C330.145 700.378 347.119 665.121 379.339 653.836C411.559 642.551 446.823 659.511 458.104 691.718Z"
          fill="url(#paint6_linear_98_755)"
        />
        <path
          opacity="0.5"
          d="M161.231 332.39C165.225 347.296 156.374 362.618 141.463 366.613C126.551 370.609 111.226 361.764 107.232 346.859C103.238 331.954 112.089 316.632 127 312.637C141.911 308.641 157.237 317.485 161.231 332.39Z"
          fill="url(#paint7_linear_98_755)"
        />
        <path
          d="M513.1 834.008C521.561 858.164 508.829 884.608 484.663 893.072C460.497 901.536 434.048 888.816 425.587 864.66C417.126 840.504 429.858 814.06 454.024 805.596C478.19 797.131 504.639 809.852 513.1 834.008Z"
          fill="url(#paint8_linear_98_755)"
        />
        <path
          opacity="0.75"
          d="M718.345 773.037C720.595 779.463 717.211 786.497 710.785 788.747C704.359 790.998 697.326 787.613 695.075 781.188C692.824 774.762 696.209 767.728 702.635 765.478C709.061 763.227 716.094 766.612 718.345 773.037Z"
          fill="url(#paint9_linear_98_755)"
        />
        <path
          opacity="0.3"
          d="M796.543 717.556C802.182 733.657 793.696 751.283 777.588 756.925C761.48 762.567 743.851 754.088 738.211 737.987C732.572 721.886 741.058 704.259 757.166 698.618C773.274 692.976 790.903 701.455 796.543 717.556Z"
          fill="url(#paint10_linear_98_755)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_98_755"
          x1="210.482"
          y1="187.792"
          x2="427.21"
          y2="806.567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" stopOpacity="0.48" />
          <stop offset="1" stopColor="#D5EDFF" stopOpacity="0.33" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_98_755"
          x1="388.346"
          y1="723.056"
          x2="253.942"
          y2="62.1597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_98_755"
          x1="714.657"
          y1="706.62"
          x2="177.029"
          y2="354.637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D5EDFF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_98_755"
          x1="627.396"
          y1="585.983"
          x2="664.788"
          y2="618.142"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_98_755"
          x1="525.921"
          y1="187.211"
          x2="535.269"
          y2="195.251"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_98_755"
          x1="748.898"
          y1="386.855"
          x2="842.599"
          y2="467.473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_98_755"
          x1="352.898"
          y1="671.855"
          x2="446.599"
          y2="752.473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_98_755"
          x1="114.466"
          y1="319.865"
          x2="153.982"
          y2="359.396"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEDCA1" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_98_755"
          x1="489.452"
          y1="807.569"
          x2="449.27"
          y2="891.116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFFDEA" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_98_755"
          x1="712.058"
          y1="766.005"
          x2="701.363"
          y2="788.223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_98_755"
          x1="780.785"
          y1="699.935"
          x2="753.995"
          y2="755.626"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#FFF59D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Step1Pattern;
