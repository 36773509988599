import { useEffect, useRef } from "react";

const useUpdateLifecycle = (effect, deps) => {
  const isInitial = useRef(true);

  useEffect(function effectHandler() {
    if (!isInitial.current) effect();
    else isInitial.current = false;
  }, deps);
};

export default useUpdateLifecycle;
