import "./index.css";
import logoType from "../../../assets/logotype.png";

const Header = () => {
  return (
    <div className="header-container">

      <img src={logoType} alt="logo" />
      <div className="gradient-bg"></div>
    </div>
  );
};
export default Header;
