// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installation-container{
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    line-height: 2rem;
    
}

.installation-container--title{
    font-size: 32px;
    font-weight: 700;
    color:#020C87 ;
    text-align: center;
    margin-bottom: 2rem;
}

.installation-container--description{
    font-size: 18px;
    font-weight: 400;
}

.installation-container--github-action{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 1rem;
    background-color: #fff;
    margin-top: 2rem;
    color: #020C87;
    font-weight: 600;
    cursor: pointer;
    gap: 1rem;
    font-size: 16px;
    box-shadow: -1px 4px 17px 0px #00000040;
    padding: 0.5rem 1.5rem;
    margin-bottom: 3rem;
    z-index: 2;
    font-family: inherit;
}


@media only screen and (max-width: 780px) {
    .installation-container--title{
        font-size: 24px;
    }
    .installation-container--description{
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/installation/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;;AAErB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,SAAS;IACT,eAAe;IACf,uCAAuC;IACvC,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,oBAAoB;AACxB;;;AAGA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".installation-container{\n    margin-top: 5rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    text-align: center;\n    line-height: 2rem;\n    \n}\n\n.installation-container--title{\n    font-size: 32px;\n    font-weight: 700;\n    color:#020C87 ;\n    text-align: center;\n    margin-bottom: 2rem;\n}\n\n.installation-container--description{\n    font-size: 18px;\n    font-weight: 400;\n}\n\n.installation-container--github-action{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: none;\n    border-radius: 1rem;\n    background-color: #fff;\n    margin-top: 2rem;\n    color: #020C87;\n    font-weight: 600;\n    cursor: pointer;\n    gap: 1rem;\n    font-size: 16px;\n    box-shadow: -1px 4px 17px 0px #00000040;\n    padding: 0.5rem 1.5rem;\n    margin-bottom: 3rem;\n    z-index: 2;\n    font-family: inherit;\n}\n\n\n@media only screen and (max-width: 780px) {\n    .installation-container--title{\n        font-size: 24px;\n    }\n    .installation-container--description{\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
