import { useEffect, useState } from "react";
import ActivePattern from "../../assets/patterns/ActivePattern";
import Footer from "../footer";
import Header from "./header/Header";
import "./index.css";
import Select from "./Select/Select";
import CheckBox from "./checkBox/CheckBox";
import logo from "../../assets/bigLogo.png";
import Modal from "../Modal/Modal";
import MobileActivePattern from "../../assets/patterns/MobileActivePattern";
import axios from "axios";
import { services } from "../../api/servisec";

const AddPage = ({ channels }) => {
  const [isChannel, setIsChannel] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const disableBtn = isChannel
    ? selectedChannel
      ? ""
      : "disable"
    : selectedUser.length
    ? ""
    : "disable";

  function onSaveChannel() {
    if (selectedChannel) {
      setIsChannel(false);
    }
  }

  function onSelectOrRemoveUser(userId) {
    if (selectedUser.includes(userId)) {
      setSelectedUser((prev) => prev.filter((id) => id !== userId));
    } else {
      setSelectedUser((prev) => [...prev, userId]);
    }
  }

  function onSaveUsers() {
    axios
      .post("https://app.kianbot.com/slack_app/add_user_report/", {
        users: selectedUser,
        channel_id: selectedChannel,
      })
      .then(() => {
        setShowModal(true);
      })
      .catch(() => {
        console.log("there is some problem");
      });
  }

  useEffect(() => {
    if (!isChannel) {
      services
        .getUserChannel(selectedChannel)
        .then((res) => {
          setUsers(res.data.channels_info);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    }
  }, [isChannel]);

  return (
    <div className="activate-page">
      <Header />
      <div className="title-container">
        <p className="title">
          Complete this step to continue activating Kianbot.
        </p>
        <div className="activate-title">
          {isChannel ? (
            <>
              <p>Select a channel</p>
              <span>
                Select the channel that you want to display report of
                colleagues.
              </span>
            </>
          ) : (
            <>
              <p>Select Members</p>
              <span>
                Select the colleagues who need to enter daily reports.
              </span>
            </>
          )}
        </div>
      </div>
      <div className="content-container">
        {isChannel ? (
          <Select
            data={channels}
            active={selectedChannel}
            onChange={setSelectedChannel}
          />
        ) : (
          <CheckBox
            data={users}
            onChange={onSelectOrRemoveUser}
            actives={selectedUser}
          />
        )}
        <div className={`btn-wrapper ${disableBtn}`}>
          {isChannel ? (
            <button onClick={onSaveChannel}>Next</button>
          ) : (
            <button onClick={onSaveUsers}>Save</button>
          )}
        </div>
      </div>
      <Footer />
      <div className="bg-pattern">
        <ActivePattern />
      </div>
      <div className="mobile-bg-pattern">
        <MobileActivePattern />
      </div>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <div className="success-modal">
          <div className="logo-background">
            <img src={logo} alt="logo" />
          </div>
          <p className="modal-title">
            Kian bot has been added successfully to your workspace.
          </p>
          <button className="modal-btn" onClick={() => setShowModal(false)}>
            Ok
          </button>
        </div>
      </Modal>
    </div>
  );
};
export default AddPage;
