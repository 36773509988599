import { Route, Routes } from "react-router";
import Home from "./pages/Home";
import { BrowserRouter } from "react-router-dom";
import Activate from "./pages/Activate";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<Home />} path="/*"></Route>
      <Route element={<Activate />} path="activate/:workspace_id"></Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
