import "./index.css";
import SkeletonLayout from "../../skeletonLayout/SkeletonLayout";

const Select = ({ data, onChange, active }) => {
  return (
    <>
      {data.length ? (
        <div className="select">
          {data.map((channel, i) => (
            <div
              key={i}
              onClick={() => onChange(channel.channel_id)}
              className={`items ${
                active === channel.channel_id ? "active" : ""
              }`}
            >
              {channel.channel_name}
            </div>
          ))}
        </div>
      ) : (
        <SkeletonLayout />
      )}
    </>
  );
};
export default Select;
