import "./index.css";
import logotype from "../../assets/logotype.png";
import bg from "../../assets/bg.png";
import flag from "../../assets/flag.png";
import { KIAN_OPTIONS } from "../../static/introStatic";
import Pattern from "../../assets/patterns/Pattern";
import MobilePattern from "../../assets/patterns/MobilePattern";
import notebook from "../../assets/notebook.png";
import shadow from "../../assets/shaddow.png";
import colors from "../../assets/colos.png";
import kianLogo from "../../assets/kianlogo.png";
import mail from "../../assets/mail.png";
import slack from "../../assets/slack.png";
import AddSlackBtn from "../addSlackBtn";

const Introduction = () => {
  return (
    <div className="container">
      <div className="logo">
        <img src={logotype} alt="kainbot-logo" />
      </div>
      <div
        className="card"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        <div className="info-container">
          <h1>
            Manage <br />
            your remote team
            <br />
            for free
          </h1>
          <div className="option_container">
            <p className="hint">Daily Bot for Slack</p>
            {KIAN_OPTIONS.map((option, i) => {
              return (
                <div key={i} className="options">
                  <img src={flag} alt="flag" />
                  <p>{option}</p>
                </div>
              );
            })}
            <div className="btn-container">
              <AddSlackBtn />
            </div>
          </div>
        </div>
      </div>
      <div className="image-container">
        <div className="background-pattern">
          <Pattern />
        </div>
        <div className="background-pattern-mobile">
          <MobilePattern />
        </div>
        <div className="notebook">
          <img src={notebook} alt="notebook" />
        </div>
        <div className="notebook shadow">
          <img src={shadow} alt="notebook" />
        </div>
        <div className="colors">
          <img src={colors} alt="colors" />
        </div>
        <div className="logo-container">
          <img src={kianLogo} alt="logo" />
        </div>
        <div className="mail">
          <img src={mail} alt="mail-logo" />
        </div>
        <div className="slack">
          <img src={slack} alt="slack-icon" />
        </div>
      </div>
    </div>
  );
};
export default Introduction;
