// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkBox{
    margin-top: 2rem;
    background-color: #fff;
    width: 600px;
    max-height: 500px;
    overflow: auto;
    border-radius: 8px;
    border: 1px solid #D4EDFF;  
}

.checkBox .items {
    padding: 1rem ;
    cursor: pointer;
    transition: 0.2;
    color: #2C3131;
    display: flex;
    align-items: center;
}
.box{
    height: 25px;
    width: 25px;
    border-radius: 0.2rem;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}

.active{
    background-color: #009CDF !important;
}

.checkBox .items:hover{
    background-color:#EBF7FD ;
}

@media only screen and (max-width: 780px) {
    .checkBox{
        width: 280px;

    }
}`, "",{"version":3,"sources":["webpack://./src/components/addPage/checkBox/index.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,eAAe;IACf,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,YAAY;;IAEhB;AACJ","sourcesContent":[".checkBox{\n    margin-top: 2rem;\n    background-color: #fff;\n    width: 600px;\n    max-height: 500px;\n    overflow: auto;\n    border-radius: 8px;\n    border: 1px solid #D4EDFF;  \n}\n\n.checkBox .items {\n    padding: 1rem ;\n    cursor: pointer;\n    transition: 0.2;\n    color: #2C3131;\n    display: flex;\n    align-items: center;\n}\n.box{\n    height: 25px;\n    width: 25px;\n    border-radius: 0.2rem;\n    border: 1px solid #D9D9D9;\n    background-color: #ffffff;\n    margin-right: 1rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: 0.3s;\n}\n\n.active{\n    background-color: #009CDF !important;\n}\n\n.checkBox .items:hover{\n    background-color:#EBF7FD ;\n}\n\n@media only screen and (max-width: 780px) {\n    .checkBox{\n        width: 280px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
